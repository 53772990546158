<template>
  <div>

    <common-table
      ref="table"
      path="deviceAlarm"
      :columns="columns"
      :queryParams="queryParams"
      :date-range="true" >

    </common-table>

  </div>
</template>
<script>
  export default {
    props:["iotId"],
    data () {
      return {
        queryParams:{
          iotId:'',
        }
      }
    },
    mounted () {
      this.queryParams.iotId = this.iotId
    },

    computed:{
      columns() {
        return [
          {
            title: "类型",
            dataIndex: "type",
          },
          {
            title: "内容",
            dataIndex: "content",
          },
          {
            title: "时间",
            dataIndex: "createdAt",
          }
        ];
      },
    },
    methods:{
      getList () {
        this.$refs.table.getData()
      },
    }
  }
</script>

<style scoped>

</style>
